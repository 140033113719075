@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    background-color: #0F0F0F;
    overflow-x: hidden;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: Heebo, sans-serif;
    color: #F0F0F0;
  }

  p, li, a, button {
    font-family: Inter, sans-serif;
    color: #878787;
  }

  .privacy-screen {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: transparent;
    z-index: 10000;
  }
}